@import url(https://rsms.me/inter/inter.css);
html { font-family: 'Inter', sans-serif; }
@supports (font-variation-settings: normal) {
  html { font-family: 'Inter var', sans-serif; }
}

@font-face {
  font-family: 'Value Serif';
  src: url(/static/media/ValueSerif-Bold.ac773fa6.eot);
  src: url(/static/media/ValueSerif-Bold.ac773fa6.eot?#iefix) format('embedded-opentype'),
      url(/static/media/ValueSerif-Bold.a6b2c400.woff2) format('woff2'),
      url(/static/media/ValueSerif-Bold.879b8991.woff) format('woff'),
      url(/static/media/ValueSerif-Bold.ad08c99e.ttf) format('truetype');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'Graphik Web';
  src: url(/static/media/Graphik-Regular-Web.6eb2abac.woff2) format('woff2'),
       url(/static/media/Graphik-Regular-Web.aa19cee4.woff) format('woff');
  font-weight:  400;
  font-style:   normal;
  font-stretch: normal;
}

html,
body,
div,
article,
section,
main,
footer,
header,
form,
fieldset,
legend,
pre,
code,
a,
h1,h2,h3,h4,h5,h6,
p,
ul,
ol,
li,
dl,
dt,
dd,
textarea,
table, 
td,
th,
tr,
input[type="email"],
input[type="number"],
input[type="password"],
input[type="tel"],
input[type="text"],
input[type="url"],
.border-box {
  box-sizing: border-box;
}


.hero{
  background-size: cover;
  background-position: top right;
}

.Graphik-Regular-Web {
  font-family: 'Graphik Web';
  font-weight:  400;
  font-style:   normal;
  font-stretch: normal;
}


a{
  color: inherit;
}

a.link.black.db:hover{
  color: white;
}

input:focus{
  outline: 1px black solid;
}

h1, .value{
  font-family: 'Value Serif', Georgia, serif;
  font-weight: bold;
}

body, html{
  width: 100%;
  margin: 3em 0 0 0;
  height: unset !important;
  /* overflow-x: hidden; */
}


.ticker{
  top: 0px;
  z-index: 99999999999;
}


/* Body AND HTML can't be both overflow-x hidden for parallax to work */


body, h2, h3, p {
  margin: 0;
  font-family: 'Graphik Web', "Inter", -apple-system, BlinkMacSystemFont,
    "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
    "Droid Sans", "Helvetica Neue", sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}


.fw2 {
  font-family: 'Graphik Web';
}

.vh-100{
  height: calc(100vh - 59px) !important;
}

::selection {
  background: #333;
  color: white;
}
::-moz-selection {
  background: #333;
  color: white;
}

/* .userItem{
  -webkit-box-shadow: 2px 2px 8px 0px rgba(0,0,0,0.1);
-moz-box-shadow: 2px 2px 8px 0px rgba(0,0,0,0.1);
box-shadow: 2px 2px 8px 0px rgba(0,0,0,0.1);
} */


.MainFooter {
  /* height: 200px;
  width: 100%;
  display: flex;
  justify-content: space-around;
  padding: 3em; */
}

.MainFooter a {
  margin: 0em 2em;
  /* font-size: 0.75em; */

}

button {
  display: inline-block;
  border: 1px solid #333;
  border-radius: 4px;
  padding: 1rem 2rem;
  margin: 0;
  text-decoration: none;
  background: #fff;
  color: #333;
  font-family: sans-serif;
  font-size: 1rem;
  cursor: pointer;
  text-align: center;
  transition: all 250ms ease-in-out;
  -webkit-appearance: none;
  -moz-appearance: none;
}

button:hover,
button:focus {
  background-color: #333333;
  border-color: #333333;
  color: #fff;
}

button:focus {
  outline: 1px solid #fff;
  outline-offset: -4px;
}

button:active {
  transform: scale(0.99);
}

.userItem {
  box-shadow: 0 12px 24px rgba(0, 0, 0, 0.05);
  transition: all 0.25s ease-in-out;
}
.form {
  box-shadow: 0 12px 24px rgba(0, 0, 0, 0.05);
  transition: all 0.25s ease-in-out;
}

.userItem:hover {
  box-shadow: 0 24px 36px rgba(0, 0, 0, 0.1);
  transform: scale(1.01);
}

.form:hover {
  box-shadow: 0 48px 48px rgba(0, 0, 0, 0.1);
}

/* Fiver update-form css */

.checkbox-button {
  cursor: pointer;
}

input[type="checkbox"] {
  box-sizing: border-box;
  padding: 0;

  font-size: 1rem;
  line-height: 1.5;
  padding: 11px 23px;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 0;
  outline: 0;
  background-color: transparent;
}

.checkbox-input {
  opacity: 0;
  position: absolute;
}

.checkbox-control {
  position: relative;
  display: inline-block;
  width: 20px;
  height: 20px;
  margin-right: 12px;
  vertical-align: middle;
  background-color: inherit;
  color: #017b5f;
  border: 1px solid #999;
}

/* .ticker{
  position: fixed !important;
  top: 0;
  color: blue;
} */

.inter{
  font-family: 'Inter', sans-serif;
}

.checkbox-input:checked + .checkbox-control:after {
  content: "✔";
  display: block;
  position: absolute;
  font-size: 2em;
  line-height: .5em;
  /* top: 5px;
  left: 5px;
  width: 10px;
  height: 10px;
  background-color: #01a982; */
}
.checkbox-input:checked + .checkbox-control {
  border-color: #000000;
}

/* .checkbox-control {
  /* transform: scale(0.75); */
/* } */ 

.checkbox-label {
  margin-left: -10px;
}

.date-picker {

  border-width: 1px;
  border-color: rgba(0, 0, 0, .2);

}

.text-center {
  text-align: center;
}

ul {
  list-style: none;
}

ul li:before {
  content: "✔";
  margin-right: .5em;
}

.iPhoneOuter{
  position: relative;
  width: 30vh;
  background: pink;
}
img.deviceImage {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    z-index: 2;
}
.iphone-video video{
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%,-50%) scale(0.88);
}

@media only screen and (min-width: 800px) {
  .hero {
    background-position: center right;
    background-size: cover;
  }
}
